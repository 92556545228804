import React from 'react';
import { IntlProvider } from 'react-intl';
import messages_en from './i18n/en.json';
import messages_id from './i18n/id.json';
import messages_cn from './i18n/cn.json';

const messages = {
  en: messages_en,
  id: messages_id,
  cn: messages_cn,
};

// eslint-disable-next-line react/prop-types
export function IntlManager({ children, locale }) {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}