// Input.tsx
import React from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
    
`;

interface TextareaProps {
    placeholder: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    name: string;
    id: string;
    value: string;
    rows: number;
}

const Textarea: React.FC<TextareaProps> = (props) => {
    return (
        <StyledTextarea className='
            border w-full p-4 rounded-xl rounded-xl bg-[#F8FAFC] border-[#CBD5E1] border-outline
        ' 
        placeholder={props.placeholder} 
        onChange={props.onChange} 
        name={props.name}
        value={props.value}
        id={props.id}
        rows={props.rows}
        />
    );
};

export default Textarea;