import React, {useEffect, useRef, useState} from "react";
import { Header_v2 } from "../../components/Blocks/Header";
import { CTA_v2 } from '../../components/Blocks/CTA';
import header_solution_crm from '../../assets/header/header_solution_crm.webp';
import email_automation from '../../assets/product/crm/email_automation.jpg';
import quotation_crm from '../../assets/product/crm/quotation_crm.jpg';
import kanban_crm from '../../assets/product/crm/kanban_crm.jpg';
import report_crm from '../../assets/product/crm/report_crm.jpg';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { StyledButton_v2 } from '../../components/UI/Button_v2';
import { FormattedMessage } from 'react-intl';

function CRM() {
  const container = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<number>(1);


  useEffect(() => {
    const scrollElement = container.current;

    if (scrollElement) {
      scrollElement.addEventListener('scroll', function(){
        const scrollTop = scrollElement.scrollTop;
        
        if (scrollTop > 435 && scrollTop < 1052) {
          setTab(2)
        } else if (scrollTop > 1052) {
          setTab(3)
        } else {
          setTab(1)
        }

      });
    }

  }, []);

  return (
    <>
      <Helmet>
          <title>CRM Solution | DSN</title>
          <meta name="description" content="Selamat datang di website resmi PT.Delta Solusi Nusantara." />
          <meta name="keywords" content="erp solution, odoo consultant, erp business" />
      </Helmet>
      <Header_v2 tag_1="crm_solution_tag_1" tag_2="crm_solution_tag_2">
        <img alt="Logo Image" src={header_solution_crm}/>
      </Header_v2>
      <section className="px-5 lg:px-20 pt-24 w-full lg:w-1/2">
          <h1 className="display-sm lg:display-lg font-semibold text-left mb-4"><FormattedMessage id="crm_solution_tag_3" /></h1>
          <p className="text-medium lg:text-extralarge font-medium w-full text-left"><FormattedMessage id="crm_solution_tag_4" /></p>
      </section>
      {/* mobile */}
      <div className="flex flex-col block lg:hidden px-5">
          <div>
              <img src={kanban_crm} width={'100%'} className=""/>
              <div className="flex flex-col justify-between display-xs font-semibold border">
                  <h3 className="mb-2"><FormattedMessage id="accounting_feature_1" /></h3>
                  <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                      <FormattedMessage id="inventory_feature_desc_1" />
                  </p>
              </div>
          </div>
          <div>
              <img src={report_crm} width={'100%'} className=""/>
              <div className="flex flex-col justify-between display-xs font-semibold border">
                  <h3 className="mb-2"><FormattedMessage id="accounting_feature_1" /></h3>
                  <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                      <FormattedMessage id="inventory_feature_desc_1" />
                  </p>
              </div>
          </div>
          <div>
              <img src={email_automation} width={'100%'} className="mb-10 lg:mb-16"/>
              <div className="flex flex-col justify-between display-xs font-semibold ">
                  <h3 className="mb-2"><FormattedMessage id="accounting_feature_1" /></h3>
                  <p className={"text-large font-medium " + (tab !== 1 ? 'hidden' : '')}>
                      <FormattedMessage id="inventory_feature_desc_1" />
                  </p>
              </div>
          </div>
      </div> 
      {/* desktop */}
      <section className="px-5 lg:px-20 pt-24 hidden lg:block">
        <div className="flex flex-row items-center gap-x-20 ">
          <div className="basis-1/2 overflow-y-scroll no-scrollbar h-[600px]" ref={container}>
            <div className="py-40">
              <h4 className="display-md font-semibold mb-4"><FormattedMessage id='crm_feature_title_1'/></h4>
              <p className="text-extralarge font-medium mb-10"><FormattedMessage id='crm_feature_desc_1'/></p>
              <div className="flex flex-col md:flex-row justify-start gap-x-4 gap-y-4 mb-[83px] md:mb-16 lg:mb-16">
                  <Link to="/demo">
                      <StyledButton_v2 type='primary' className='w-full lg:min-w-fit'>
                          <FormattedMessage id='demo_button_2' />
                      </StyledButton_v2>
                  </Link>
                  <div>
                      <StyledButton_v2 type='border' className='w-full lg:min-w-fit font-semibold'>
                          <FormattedMessage id='nav_contact_us' />
                      </StyledButton_v2>
                  </div>
              </div>
            </div>
            <div className="py-40">
              <h4 className="display-md font-semibold mb-4"><FormattedMessage id='crm_feature_title_2'/></h4>
              <p className="text-extralarge font-medium mb-10"><FormattedMessage id='crm_feature_desc_2'/></p>
              <div className="flex flex-col md:flex-row justify-start gap-x-4 gap-y-4 mb-[83px] md:mb-16 lg:mb-16">
                  <Link to="/demo">
                      <StyledButton_v2 type='primary' className='w-full lg:min-w-fit'>
                          <FormattedMessage id='demo_button_2' />
                      </StyledButton_v2>
                  </Link>
                  <div>
                      <StyledButton_v2 type='border' className='w-full lg:min-w-fit font-semibold'>
                          <FormattedMessage id='nav_contact_us' />
                      </StyledButton_v2>
                  </div>
              </div>
            </div>
            <div className="py-40">
              <h4 className="display-md font-semibold mb-4"><FormattedMessage id='crm_feature_title_3'/></h4>
              <p className="text-extralarge font-medium mb-10"><FormattedMessage id='crm_feature_desc_3'/></p>
              <div className="flex flex-col md:flex-row justify-start gap-x-4 gap-y-4 mb-[83px] md:mb-16 lg:mb-16">
                  <Link to="/demo">
                      <StyledButton_v2 type='primary' className='w-full lg:min-w-fit'>
                          <FormattedMessage id='demo_button_2' />
                      </StyledButton_v2>
                  </Link>
                  <div>
                      <StyledButton_v2 type='border' className='w-full lg:min-w-fit font-semibold'>
                          <FormattedMessage id='nav_contact_us' />
                      </StyledButton_v2>
                  </div>
              </div>
            </div>

          </div>
          <div className="basis-1/2">
            <img className='transition' alt="Logo Image" src={tab == 1 ? kanban_crm : tab == 2 ? report_crm : email_automation}/>
          </div>
        </div>
      </section>

      <section className="px-5 lg:px-20 py-24">
          <h1 className="display-lg font-semibold text-center"><FormattedMessage id='crm_solution_tag_5'/></h1>
          <p className="text-extralarge font-medium w-3/5 text-center mx-auto mb-16"><FormattedMessage id='crm_solution_tag_6'/></p>
          <img src={quotation_crm} width={'100%'}/>
      </section>
      <CTA_v2/>
    </>
  );
}

export default CRM;
