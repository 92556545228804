// Label.tsx
import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    color: #0F172A;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;

interface LabelProps {
    title: string;
    for: string;
}

const Label: React.FC<LabelProps> = (props) => {
    return (
        <StyledLabel htmlFor={props.for}>
            {props.title}
        </StyledLabel>
    );
};

export default Label;