// Button.tsx
import styled from 'styled-components';

interface ButtonProps {
  type?: 'primary' | 'tertiary' | 'border';
  disabled?: boolean;
}

export const StyledButton_v2 = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.type === 'primary'
      ? '#EC5444'
      : props.type === 'tertiary'
      ? '#FFFFFF'
      : props.type === 'border'
      ? '#FFFFFF'
      : '#FFFFFF'};
  color: ${(props) => (props.type == 'primary' ? '#FFFFFF' : props.type == 'tertiary' ? '#64748B' : '#64748B')};
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 550;
  transition: .1s;
  border: ${(props) => (props.type == 'primary' ? 'none' : '1px solid')};
  border-color: ${(props) => (props.type == 'tertiary' ? 'transparent' : '#CBD5E1')};
  border-radius: 12px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  &:hover {
    background-color: ${(props) =>
      props.type === 'primary'
        ? '#BE4337'
        : props.type === 'tertiary'
        ? '#F1F5F9'
        : '#F1F5F9'};
  }
`;
