import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../stateStore'
import { TrueFalseServices } from '../reducers/TrueFalseSlice'
import { StyledButton_v2 } from '../components/UI/Button_v2';

import Input from '../components/Forms/Input';
import Label from '../components/Forms/Label';
import Textarea from '../components/Forms/Textarea';

import { Icon } from '@iconify-icon/react';
import { Link } from 'react-router-dom';
import axios  from 'axios';
import { urls } from '../utils/constants';
import { Helmet } from 'react-helmet-async';

import * as z from 'zod';

// validation schema
const demoSchema = z.object({
    name: z.string().min(5, "Masukkan nama kamu."),
    to: z.string().email("Alamat email tidak valid."),
    text: z.string()
        .nonempty({ message: "Masukkan pesan." })
        .max(500, "Tidak boleh lebih dari 500 karatker."),
    company: z.string().max(100, "Tidak boleh lebih dari 100 karakter."),
    telp: z.string()
        .nonempty({ message: "Masukkan No Telp kamu." })
        .regex(/^\d+$/, { message: "No Telp harus berupa angka." }),
});

type DemoFormData = z.infer<typeof demoSchema>;

type Errors = Partial<Record<keyof DemoFormData, { _errors: string[] }>>;

interface EmailData {
    name: string;
    to: string;
    subject: string;
    text: string;
    company: string;
    telp: string;
}

interface ResponseMessage {
    message: string;
}

function Demo() {
    const [responseMessage, setResponseMessage] = useState<string>('');
    const [bgDemo, setBgDemo] = useState<string>('bg-demo1');
    const [moduleName, setModuleName] = useState<string>('Accounting');
    const [moduleDescription, setModuleDescription] = useState<string>('Kelola keuangan bisnis kamu secara real-time');
    const [errors, setErrors] = useState<Partial<Record<keyof DemoFormData, string[]>>>({});
    const [emailData, setEmailData] = useState<EmailData>({
        name: '',
        to: '',
        subject: 'Permintaan Demo',
        text: '',
        company: '',
        telp: ''
    });
    const [sendButtonState, setSendButtonState] = useState(false)
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // ZOD form validation
            const result = demoSchema.safeParse(emailData);
            
            if (!result.success) {
                const fieldErrors = result.error.format() as Partial<Errors>;
                const allErrors: Partial<Record<keyof DemoFormData, string[]>> = {};

                Object.entries(fieldErrors).forEach(([key, value]) => {
                    const fieldError = value?._errors;
                    if (fieldError) {
                        allErrors[key as keyof DemoFormData] = fieldError;
                    }
                });
                
                setErrors(allErrors);

            } else {
                console.log("Form data is valid! Saatnya kirim email", emailData);
                const response = await axios.post<ResponseMessage>(`${urls.API_URL_v2}send-email`, emailData);
                setResponseMessage(response.data.message);
                setSendButtonState(true);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                setResponseMessage(`Error: ${error.response.data.message}`);
            } else {
                setResponseMessage(`Unexpected Error: ${error instanceof Error ? error.message : 'An unknown error occurred'}`);
            }
        }
    };

    const dispatch = useAppDispatch()
    

    // misscelaneous animation
    const resetWidth = () => {
        
        setBgDemo('bg-demo1');

        const bar1 = document.getElementById('bar1');
        const bar2 = document.getElementById('bar2');
        const bar3 = document.getElementById('bar3');
        
        if (bar1) bar1.style.width = '0%';
        if (bar2) bar2.style.width = '0%';
        if (bar3) bar3.style.width = '0%';


    }

    const intervalRef = useRef<number | null>(null);

    useEffect(() => {
        dispatch(TrueFalseServices.actions.setToTrue())
        
        animateBar('bar1');

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const animateBar = (bar: string) => {
        if (bar === 'bar1') resetWidth();
        let i = 0;

        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
        }
    
        intervalRef.current = window.setInterval(() => {
          const element = document.getElementById(bar);
          const bg_demo = document.getElementById('bg_demo');
    
          if (element && bg_demo) {
            element.style.width = i + "%";
            if (i <= 30) {
              bg_demo.style.opacity = (i * 10) + "%";
            }
            if (i >= 80) {
              bg_demo.style.opacity = ((100 - i) * 5) + "%";
            }
            i += 0.5;
    
            if (i >= 100) {
              clearInterval(intervalRef.current!);
              if (bar === 'bar1') {
                setBgDemo('bg-demo2');
                setModuleName('HR');
                setModuleDescription('Maksimalkan potensi sumber daya dan kinerja pekerja');
                animateBar('bar2');
              } else if (bar === 'bar2') {
                setBgDemo('bg-demo3');
                setModuleName('CRM');
                setModuleDescription('Pantau setiap penjualan dan layanan pelanggan dengan efektif');
                animateBar('bar3');
              } else if (bar === 'bar3') {
                animateBar('bar1');
              }
            }
          }
        }, 15);
    };


    return (
        <>
            <Helmet>
                <title>Demo - Delta Solusi Nusantara</title>
                <meta name="description" content="Welcome to the Landing Page of MyApp." />
                <meta name="keywords" content="landing, myapp, react" />
            </Helmet>
            
            <main className='w-full' >
                <section className='flex flex-col lg:flex-row'>
                {sendButtonState && (
                    <>
                        <div className='basis-1/2 flex flex-col gap-y-6 my-16 px-5 lg:px-20 justify-center'>
                            
                                <div className='mx-auto p-2 bg-red-100 rounded-xl'>
                                    <div className='p-2 bg-red-500 rounded-xl'>
                                        <Icon icon="bi:envelope-check-fill" className='text-4xl text-white'/>
                                    </div>
                                </div>
                                <h2 className='text-3xl font-medium text-center w-11/12 mx-auto text-center'>Terima kasih sudah
                                menghubungi kami</h2>
                                <p className='text-center text-lg text-gray-500'>Kami akan menghubungi kamu secepatnya</p>
                                <Link to="/" className='lg:mx-auto'>
                                    <StyledButton_v2 type='primary' className='w-full lg:w-auto'>Kembali ke Halaman Utama</StyledButton_v2>
                                </Link>
                        </div>
                    </>
                )}
                    <div className={"basis-1/2 flex flex-col gap-y-6 py-14 px-5 lg:px-20 h-screen overflow-scroll no-scrollbar " + (sendButtonState ? 'hidden' : 'block')}>
                        <div className='py-1.5 px-2 bg-red-50 flex flex-row rounded-lg justify-center gap-x-2 items-center max-w-fit'>
                            <div className='p-1 bg-red-500 flex justify-center items-center rounded-lg'>
                                <Icon icon="ic:round-widgets" className='text-white'/>
                            </div>
                            <div className='text-red-500 font-semibold'>Demo Gratis</div>
                        </div>
                        <h2 className='display-sm font-semibold '>Transformasi bisnis kamu dimulai dari sini, daftar untuk Demo Gratis!</h2>
                        <form className='border rounded-3xl p-6 flex flex-col gap-y-8' onSubmit={handleSubmit}>
                            <div className='flex flex-col gap-y-3'>
                                <Label for="name" title="Nama Lengkap" />
                                <Input 
                                    type='text' 
                                    placeholder='Nama lengkap kamu' 
                                    value={emailData.name}
                                    name="name"
                                    id="name"
                                    onChange={handleChange} />
                                {errors.name && errors.name.map((error, index) => (
                                    <div key={index} className="text-sm text-red-500 font-medium">
                                        {error}
                                    </div>
                                ))}
                            </div>
                            <div className='flex flex-col gap-y-3'>
                                <Label for="company" title="Nama Perusahaan"/>
                                <Input 
                                    type='text' 
                                    placeholder='Nama perusahaan kamu' 
                                    value={emailData.company}
                                    name="company"
                                    id="company"
                                    onChange={handleChange} />
                                {errors.company && errors.company.map((error, index) => (
                                    <div key={index} className="text-sm text-red-500 font-medium">
                                        {error}
                                    </div>
                                ))}
                            </div>
                            <div className='flex flex-col lg:flex-row gap-x-4 gap-y-8'>
                                <div className='basis-1/2 flex flex-col gap-y-3'>
                                    <Label for="email" title="Email"/>
                                    <Input 
                                        type='email' 
                                        placeholder='Email kamu' 
                                        value={emailData.to}
                                        name="to"
                                        id="email"
                                        onChange={handleChange} />
                                    {errors.to && errors.to.map((error, index) => (
                                    <div key={index} className="text-sm text-red-500 font-medium">
                                        {error}
                                    </div>
                                ))}
                                </div>
                                <div className='basis-1/2 flex flex-col gap-y-3'>
                                    <Label for="telp" title="Nomor Telepon/Whatsapp"/>
                                    <Input 
                                        type='text' 
                                        placeholder='Nomor kamu' 
                                        value={emailData.telp}
                                        name="telp"
                                        id="telp"
                                        onChange={handleChange} />
                                    {errors.telp && errors.telp.map((error, index) => (
                                    <div key={index} className="text-sm text-red-500 font-medium">
                                        {error}
                                    </div>
                                ))}
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <Label for="text" title="Pesan"/>
                                <Textarea name="text" id="text" placeholder='Saya butuh sistem untuk mengelola stok di gudang saya' rows={4} value={emailData.text} onChange={handleChange} />
                                {errors.text && errors.text.map((error, index) => (
                                    <div key={index} className="text-sm text-red-500 font-medium">
                                        {error}
                                    </div>
                                ))}
                            </div>
                            <button type='submit' className="py-3 px-3 rounded-lg bg-red-500 hover:bg-red-600 text-gray-100 text-base font-semibold">Demo Gratis</button>
                        </form>
                    </div>
                    <div className='basis-1/2 bg-gradient-second-pattern bg-no-repeat bg-cover relative overflow-hidden h-screen'>
                        <div className='flex flex-col lg:flex-row px-10 my-14 gap-y-6'>
                            <div className='basis-1/2'>
                                <div className='p-1 bg-red-50 rounded-2xl'>
                                    <div className='p-2 bg-white rounded-2xl border-red-200 border'>
                                        <h1 className='p-0 m-0 text-xl font-medium text-red-500 mb-2'>{moduleName}</h1>
                                        <p className='leading-6 text-base font-medium'>{moduleDescription}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='basis-1/2 flex flex-row gap-x-2 items-center justify-center lg:justify-end px-8'>
                                <div className='w-10 bg-white/50 h-1 rounded-full overflow-hidden' >
                                    <div className='h-full bg-white' id='bar1'></div>
                                </div>
                                <div className='w-10 bg-white/50 h-1 rounded-full overflow-hidden'>
                                    <div className='h-full bg-white' id="bar2"></div>
                                </div>
                                <div className='w-10 bg-white/50 h-1 rounded-full overflow-hidden'>
                                    <div className='h-full bg-white' id="bar3"></div>
                                </div>
                            </div>
                        </div>
                        <div className={'lg:absolute ml-10 h-[326px] md:h-[5185px] lg:h-[525px] w-full bottom-0 bg-no-repeat bg-cover ' +(bgDemo)} id="bg_demo"></div>
                    </div>
                </section>
                <section>
                    <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 grid-flow-row gap-y-8 px-5 lg:px-32 py-20">
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6923.svg'} style={{backgroundSize: 'cover'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6927.svg'} style={{backgroundSize: 'cover'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6933.svg'} style={{backgroundSize: 'cover'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6934.svg'} style={{backgroundSize: 'cover'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6936.svg'} style={{backgroundSize: 'cover'}}/>
                        <img alt="Logo Image" src={process.env.PUBLIC_URL + '/img/logo1/Frame 6938.svg'} style={{backgroundSize: 'cover'}}/>
                    </div>
                </section>
                {responseMessage && <p className='hidden'>{responseMessage}</p>}
            </main>
            
        </>

    );
}

export default Demo;