import { createSlice } from '@reduxjs/toolkit'

interface LocaleInitialStateType {
    currentLocale: string
}

const initialState: LocaleInitialStateType = {
    currentLocale: 'id'
}

const LocaleSlice = createSlice({
    name: "localeSlice",
    initialState,
    reducers: {
        changeLocale: (state, action) => {
            state.currentLocale = action.payload;
        }
    }
})

export const LocaleServices = {
    actions: LocaleSlice.actions
}

const LocaleReducer = LocaleSlice.reducer
export default LocaleReducer