import styled from 'styled-components';

interface BadgeProps {
    type?: 'primary' | 'secondary';
}

export const StyledBadge = styled.div<BadgeProps>`
    background-color: #FDEEEC;
    color: #EC5444;
    font-size: 14px;
    padding: 12px 24px;
    width: fit-content;
    font-weight: 500;
    padding: 8px, 16px, 8px, 16px;
    border-radius: 8px;
`;
    