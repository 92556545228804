import React, { ReactNode } from 'react';
import { Link } from "react-router-dom";
import { StyledButton_v2 } from '../UI/Button_v2';
import { FormattedMessage } from 'react-intl';

interface Header_v1_props {
    tag_1: string;
    tag_2: string;
    children?: ReactNode;
}

export const Header_v1: React.FC<Header_v1_props> = ({ tag_1, tag_2, children }) => {
    const handleScrollDown = () => {
        window.scrollTo({
            top: 1600,
            left: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <section className='bg-gradient-pattern bg-cover overflow-hidden'>
                <div className="mx-4 md:mx-20 lg:mx-auto lg:w-[800px] flex flex-col" >

                    <h1 className="mt-20 md:mt-16 lg:mt-[76px] mb-4 md:mb-6 lg:mb-6 text-center font-semibold text-4xl md:text-6xl lg:text-7xl leading-[44px] md:leading-[72px] lg:leading-[5rem] -tracking-[2%] px-4">
                        <FormattedMessage id={tag_1} />
                    </h1>
                    <h3 className="mx-auto mb-8 md:mb-12 lg:mb-12 text-center text-medium md:text-extralarge lg:md:text-extralarge font-medium md:w-[468px] lg:w-[598px]">
                        <FormattedMessage id={tag_2} />
                    </h3>

                    <div className="flex flex-col md:flex-row justify-center gap-x-4 gap-y-4 mb-[83px] md:mb-16 lg:mb-16">
                        <Link to="/demo">
                            <StyledButton_v2 type='primary' className='w-full lg:min-w-fit'>
                                <FormattedMessage id="demo_button" />
                            </StyledButton_v2>
                        </Link>
                        <div>
                            <StyledButton_v2 type='border' className='w-full lg:min-w-fit font-semibold' style={{color: 'red'}} onClick={handleScrollDown}>
                                <FormattedMessage id="learn_button" />
                            </StyledButton_v2>
                        </div>
                    </div>
                </div>
                <div className='border-b overflow-hidden relative' >
                    { children }
                </div>
            </section>
        </>
    );
}


{/* 
    EXAMPLE USAGE:

    <Header_v2 tag_1="manufacture_solution_tag_1" tag_2="manufacture_solution_tag_2">
        <img className='lg:absolute z-20 bottom-0 right-0' alt="Logo Image" src={process.env.PUBLIC_URL + '/img/product/ProductImage.webp'} style={{backgroundSize: 'cover', width: '95%'}}/>
    </Header_v2>
*/}

interface Header_v2_props {
    tag_1: string;
    tag_2: string;
    children?: ReactNode;
}

export const Header_v2: React.FC<Header_v2_props> = ({ tag_1, tag_2, children }) => {
    return (
        <>
            <div className="flex flex-col lg:flex-row gap-y-8 pt-12 lg:pt-16">
                <div className="w-full basis-1/2 px-5 lg:px-20">
                    <h1 className="display-md lg:display-2xl font-semibold mb-4 text-center lg:text-left">
                        <FormattedMessage id={tag_1} />
                    </h1>
                    <p className="text-medium lg:text-extralarge font-medium mb-10 text-center lg:text-left">
                        <FormattedMessage id={tag_2} />
                    </p>
                    <div className="flex flex-col lg:flex-row gap-y-4 gap-x-4">
                        <Link to="/demo">
                            <StyledButton_v2 type='primary' className='w-full lg:min-w-fit'>
                                <FormattedMessage id='demo_button_2' />
                            </StyledButton_v2>
                        </Link>
                        <Link to="/contact">
                            <StyledButton_v2 type='border' className='w-full lg:min-w-fit'>
                                <FormattedMessage id='nav_contact_us' />
                            </StyledButton_v2>
                        </Link>
                    </div>
                </div>
                <div className="w-full h-full basis-1/2 relative overflow-hidden">
                    { children }
                </div>
            </div>
        </>
    );
}