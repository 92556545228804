import React from 'react';
import { Helmet } from 'react-helmet-async';
import { StyledIcon } from '../components/UI/Icons';
import { CTA_v3 } from '../components/Blocks/CTA';

function CaseStudy() {
    return(
        <>
            <Helmet>
                <title>Case Study - PT. Delta Solusi Nusantara</title>
                <meta name="description" content="Selamat datang di website resmi PT.Delta Solusi Nusantara." />
                <meta name="keywords" content="erp solution, odoo consultant, erp business" />
            </Helmet>
            
            <main className='px-5 md:px-10 lg:px-48'>
                <section className='my-12 lg:my-24'>
                    <img alt="Logo" className='' src={process.env.PUBLIC_URL + '/img/logo_nnp.svg'} style={{backgroundSize: 'cover', width: '100px'}}/>
                    <h1 className='display-sm md:display-md lg:display-xl font-semibold'>Enhancing IT Operations with ERP Solutions from Delta Solusi Nusantara</h1>
                    {/* fun fact section */}
                    <div className='flex flex-col md:flex-row lg:flex-row mb-24'>
                        <div className='border-b md:border-r md:border-b-0 lg:border-r pb-6 basis-2/6'>
                            <h1 className='display-lg font-semibold text-primary'>25%</h1>
                            <p className='text-large font-medium'>Project completion rates improved by 25%, with 95% delivered on time and sdwithin budget.</p>
                        </div>
                        <div className='border-b md:border-r md:border-b-0 lg:border-r pb-6 pl-6 basis-2/6'>
                            <h1 className='display-lg font-semibold text-primary'>30%</h1>
                            <p className='text-large font-medium'>Automation of routine tasks resulted in a 30% reduction in manual errors</p>
                        </div>
                        <div className='border-b md:border-b-0 pb-6 pl-6 basis-2/6'>
                            <h1 className='display-lg font-semibold text-primary'>20%</h1>
                            <p className='text-large font-medium'>Overall operational costs decreased by 15% in the first year, while supporting a 40% increase in client projects.</p>
                        </div>
                    </div>
                    <div className='w-full h-[480px] border rounded-3xl overflow-hidden' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/desktop/nnp-case.jpg'})`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
                </section>
                <section className='flex flex-col-reverse lg:flex-row justify-between mb-24'>
                    <div className='mr-10'>
                        <div className='mb-16'>
                            <h3 className='display-sm font-semibold mb-4'>Background</h3>
                            <p className='text-large font-medium'>Nusa Network Prakarsa is a leading IT solutions provider with over 10 years of experience, offering a wide range of services, including Enterprise Network Infrastructure, Security Infrastructure, Data Center Infrastructure, Professional Services, and Internet of Things (IoT) solutions. Serving businesses globally, the organization leverages cutting-edge technology to optimize IT resources and enhance operational efficiency.</p>
                        </div>

                        <div className='mb-16'>
                            <p className='text-large font-semibold text-dark mb-6'>The Challenge</p>
                            <h3 className='display-sm font-semibold mb-4'>Facing operational challenges in a growing environment</h3>
                            <p className='text-large font-medium'>As Nusa Network Prakarsa expanded its operations and client base, they faced growing complexity in managing their internal processes. This included project management, resource allocation, and maintaining a streamlined workflow across multiple departments. The company required a robust, scalable solution to manage its business operations effectively and continue delivering high-quality IT services without disruption.</p>
                        </div>
                        <div className='mb-16'>
                            <p className='text-large font-semibold text-dark mb-6'>The Solutions</p>
                            <h3 className='display-sm font-semibold mb-4'>Implementing a customized ERP System with Delta Solusi Nusantara</h3>
                            <p className='text-large font-medium mb-4'>To address these challenges, Nusa Network Prakarsa partnered with Delta Solusi Nusantara, an ERP solutions provider known for its expertise in streamlining business operations. Delta Solusi Nusantara implemented a customized ERP system that included the following modules:</p>
                            <ul className='text-large font-medium list-disc ml-6 flex flex-col gap-y-2'>
                                <li>Inventory Management: Streamlined inventory tracking and control, reducing stock discrepancies.</li>
                                <li>CRM and Sales: Enhanced customer relationship management, improving lead conversion rates.</li>
                                <li>Purchase Management: Automated procurement processes, optimizing supplier interactions.</li>
                                <li>Accounting: Integrated financial management, improving accuracy in reporting and compliance.</li>
                                <li>HR Management: Improved employee management processes, streamlining payroll and performance tracking.</li>
                            </ul>
                        </div>
                        <div>
                            <p className='text-large font-semibold text-dark mb-6'>The Results</p>
                            <h3 className='display-sm font-semibold mb-4'>With the new ERP system in place, 
                            Nusa Network Prakarsa experienced:</h3>
                            <ul className='text-large font-medium list-disc ml-6 flex flex-col gap-y-2'>
                                <li>Increased operational efficiency: Automation of routine tasks led to a <span className='font-semibold'>30% reduction</span> in manual errors.</li>
                                <li>Improved resource management: Project completion rates improved by 25%, with 95% of projects delivered on time and within budget.</li>
                                <li>Scalability: The ERP system supported a 40% increase in client projects without adding to operational complexity.</li>
                                <li>Enhanced collaboration: Inter-departmental communication improved, resulting in a 50% faster decision-making process.</li>
                                <li>Cost savings: Overall operational costs were reduced by 15% within the first year of implementation.</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <ul className='min-w-60 border p-6 rounded-3xl'>
                            <li className='mb-6'>
                                <span className='text-medium font-semibold text-dark'>Nama Perusahaan</span>
                                <p className='text-medium font-medium'>Nusa Network Prakasa</p>
                            </li>
                            <li className='mb-6'>
                                <span className='text-medium font-semibold text-dark'>Skala Perusahaan</span>
                                <p className='text-medium font-medium'>Sedang-Besar</p>
                            </li>
                            <li className='mb-6'>
                                <span className='text-medium font-semibold text-dark'>Industri</span>
                                <p className='text-medium font-medium'>Informasi Teknologi</p>
                            </li>
                            <li>
                                <span className='text-medium font-semibold text-dark'>Produk yang digunakan</span>
                                <div className='text-medium font-medium flex flex-row gap-x-2 mt-3'>
                                    <StyledIcon name="accounting"/>
                                    <p>Accounting</p>
                                </div>
                                <div className='text-medium font-medium flex flex-row gap-x-2 mt-3'>
                                    <StyledIcon name="faktur"/>
                                    <p>Faktur</p>
                                </div>
                                <div className='text-medium font-medium flex flex-row gap-x-2 mt-3'>
                                    <StyledIcon name="crm"/>
                                    <p>CRM</p>
                                </div>
                                <div className='text-medium font-medium flex flex-row gap-x-2 mt-3'>
                                    <StyledIcon name="purchase"/>
                                    <p>Purchase</p>
                                </div>
                                <div className='text-medium font-medium flex flex-row gap-x-2 mt-3'>
                                    <StyledIcon name="hr"/>
                                    <p>Human Resources</p>
                                </div>
                                <div className='text-medium font-medium flex flex-row gap-x-2 mt-3'>
                                    <StyledIcon name="inventory"/>
                                    <p>Inventory</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section>
                    <video className="w-full h-[345px] border rounded-3xl object-cover" poster={process.env.PUBLIC_URL + '/img/desktop/nnp-case.jpg'} controls>
                        <source src="https://pagedone.io/asset/uploads/1705298724.mp4" type="video/mp4" /> 
                        Your browser does not support the video tag.
                    </video>
                </section>    
            </main>
            <CTA_v3/>
            
        </>
    )
}

export default CaseStudy


