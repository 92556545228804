import {createSlice} from '@reduxjs/toolkit'

interface TrueFalseInitialStateType {
    currentValue: boolean
}

const initialState: TrueFalseInitialStateType = {
    currentValue: true
}

const TrueFalseSlice = createSlice({
    name: 'TrueFalseSlice',
    initialState,
    reducers: {
        setToTrue: (state) => {
            state.currentValue = true
        },
        setToFalse: (state) => {
            state.currentValue = false
        }
    }
})

export const TrueFalseServices = {
    actions: TrueFalseSlice.actions,
}

const TrueFalseReducer = TrueFalseSlice.reducer
export default TrueFalseReducer