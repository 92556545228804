import styled from 'styled-components';

interface IconProps {
    name: string;
}

export const StyledIcon = styled.img.attrs<IconProps>(props => ({
    src: `${process.env.PUBLIC_URL}/img/icon/${props.name}.svg`,
    alt: `Icon ${props.name}`
  }))<IconProps>`
  background-size: 'cover';
`