// Input.tsx
import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    
`;

interface InputProps {
    placeholder: string;
    type: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    id: string;
    value: string;
}

const input: React.FC<InputProps> = (props) => {
    return (
        <StyledInput className='
            border w-full p-3 h-12 rounded-xl bg-[#F8FAFC] border-[#CBD5E1] border-outline
        ' 
        type={props.type} 
        placeholder={props.placeholder} 
        onChange={props.onChange} 
        name={props.name}
        value={props.value}
        id={props.id}
        />
    );
};

export default input;